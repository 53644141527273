import apiClient from './axiosInstance'; // Import the Axios instance

export const signUp = async (formData) => {
  try {
    const response = await apiClient.post('bm_organization/sign_up', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendOTP = async (formData) => {
  try {
    const response = await apiClient.post('bm_otp/send_otp', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOTP = async (formData) => {
  try {
    const response = await apiClient.post('bm_otp/verify_otp', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (formData) => {
  try {
    const response = await apiClient.post('bm_user/sign_in', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getToken = async (data) => {
    const headers = {
        Authorization: data,
      };
    try {
      const response = await apiClient.get('bm_organization/getDetails', {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
