import React, { useState, useEffect } from 'react';

const OtpPopup = ({ isVisible, onClose, onSubmit }) => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);

    useEffect(() => {
        if (isVisible) {
            document.getElementById('otp-input-0').focus();
        }
    }, [isVisible]);

    const handleChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value.slice(0, 1); 
        setOtp(newOtp);
        if (value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
        if (!value && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const completeOtp = otp.join('');
        onSubmit(completeOtp);
    };

    return (
        isVisible && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
                <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
                    <header className="mb-8">
                        <h1 className="text-2xl font-bold mb-1">Mobile Phone Verification</h1>
                        <p className="text-[15px] text-slate-500">
                            Enter the 6-digit verification code that was sent to your phone number.
                        </p>
                    </header>
                    <form id="otp-form" onSubmit={handleSubmit}>
                        <div className="flex items-center justify-center gap-3">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    id={`otp-input-${index}`}
                                    type="text"
                                    className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onFocus={(e) => e.target.select()}
                                    pattern="\d*"
                                    maxLength={1}
                                />
                            ))}
                        </div>
                        <div className="max-w-[260px] mx-auto mt-4">
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
                            >
                                Verify Account
                            </button>
                        </div>
                    </form>
                    <div className="text-sm text-slate-500 mt-4">
                        Didn't receive code?{' '}
                        <a className="font-medium text-indigo-500 hover:text-indigo-600" href="#0">
                            Resend
                        </a>
                    </div>
                    <button onClick={onClose} className="mt-4 text-indigo-500 hover:text-indigo-600">
                        Close
                    </button>
                </div>
            </div>
        )
    );
};

export default OtpPopup;
