import React from 'react'
import Header from '../../Components/Header';
import Sidebar from '../../Components/SideBar';
function Dashboard() {
  return (
    <>
         <Header />
         <Sidebar dashboard='active' />         
    </>
  )
}

export default Dashboard