import axios from 'axios';

const API_URL = 'https://api-dev.infyrealty.com/';

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
  },
});

export default apiClient;
