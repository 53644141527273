import React, { useState } from 'react';

function Projectcard({ project }) {
    const [activeTab, setActiveTab] = useState('about');

    return (
        <>
            <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 rounded-t-lg bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800" role="tablist">
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('about')}
                            className={`inline-block p-4 rounded-t-lg ${activeTab === 'about' ? 'text-blue-600 dark:text-blue-500 bg-gray-100 dark:bg-gray-700' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}`}
                        >
                            Project Detail
                        </button>
                    </li>
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('services')}
                            className={`inline-block p-4 rounded-t-lg ${activeTab === 'services' ? 'text-blue-600 dark:text-blue-500 bg-gray-100 dark:bg-gray-700' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}`}
                        >
                            Additional Information
                        </button>
                    </li>
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('statistics')}
                            className={`inline-block p-4 rounded-t-lg ${activeTab === 'statistics' ? 'text-blue-600 dark:text-blue-500 bg-gray-100 dark:bg-gray-700' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}`}
                        >
                            Configure Project
                        </button>
                    </li>
                </ul>

                {/* Content for each tab */}
                <div id="defaultTabContent">
                    {/* About Tab */}
                    {activeTab === 'about' && (
                        <div className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800">
                            <h2 className="mb-3 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                                {project.name}
                            </h2>
                            <div className="mb-3 text-gray-500 dark:text-gray-400 flex flex-wrap gap-4">
                                <p className="flex-1 min-w-[250px]">Email : {project.email}</p>
                                <p className="flex-1 min-w-[250px]">
                                    Contact No. : {project.contact_code} {project.contact_no}
                                </p>
                                Address : <p className="flex-1 min-w-[250px]">{project.address1}</p>
                            </div>
                            <div className="mb-3 text-gray-500 dark:text-gray-400 flex flex-wrap gap-4">
                                Project Type : {project.project_type}
                            </div>
                        </div>
                    )}

                    {/* Services Tab */}
                    {activeTab === 'services' && (
                        <div className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800">
                            <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                                Tags
                            </h2>
                            <ul role="list" className="space-y-4 text-gray-500 dark:text-gray-400">
                                {project.tags.map((tag)=>(
                                    <li className="flex items-center space-x-2 rtl:space-x-reverse">
                                    <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    <span className="leading-tight">{tag}</span>
                                 </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Facts Tab */}
                    {activeTab === 'statistics' && (
                        // <div className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800">
                        //     <dl className="grid max-w-screen-xl grid-cols-2 gap-8 p-4 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-6 dark:text-white sm:p-8">
                        //         <div className="flex flex-col">
                        //             <dt className="mb-2 text-3xl font-extrabold">73M+</dt>
                        //             <dd className="text-gray-500 dark:text-gray-400">Developers</dd>
                        //         </div>
                        //         <div className="flex flex-col">
                        //             <dt className="mb-2 text-3xl font-extrabold">100M+</dt>
                        //             <dd className="text-gray-500 dark:text-gray-400">Public repositories</dd>
                        //         </div>
                        //         <div className="flex flex-col">
                        //             <dt className="mb-2 text-3xl font-extrabold">1000s</dt>
                        //             <dd className="text-gray-500 dark:text-gray-400">Open source projects</dd>
                        //         </div>
                        //     </dl>
                        // </div>
                        <div className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800">
                            <a href="#" className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
                                Configure Project
                                <svg className="w-2.5 h-2.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                </svg>

                            </a>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Projectcard;
