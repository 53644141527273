import React,{useState, useEffect} from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
function ProjectView() {
    const [products, setProducts] = useState([{
        'code' : 1234,
        'name' : 'Iphone',
        'category' : 'mobile',
        'quantity' : 16
    }]);   
  return (
    <>
         <div className="card">
            <DataTable value={products} tableStyle={{ minWidth: '50rem' }}>
                <Column field="code" header="Code"></Column>
                <Column field="name" header="Name"></Column>
                <Column field="category" header="Category"></Column>
                <Column field="quantity" header="Quantity"></Column>
            </DataTable>
        </div>
        <div className="card flex justify-content-center">
            <Button label="Check" icon="pi pi-check" />
        </div>
    </>
  )
}

export default ProjectView