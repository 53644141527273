import React, { useState, useEffect } from 'react';
import Header from '../../Components/Header';
import Sidebar from '../../Components/SideBar';
import Projectcard from '../../Components/projectcard';
import './style.css';
import ProjectForm from './ProjectForm';
import { useSelector, useDispatch } from 'react-redux';
import { clearAuth } from '../../features/Auth/authSlice';
import { loadProjectLists } from '../../Services/ProjectService';

function Project() {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [projectList, setProjectList] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const token = useSelector((state) => state.auth.token);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const dispatch = useDispatch();

    const toggleForm = () => {
        setIsFormOpen(!isFormOpen);
    };

    const closeForm = () => {
        setIsFormOpen(false);
    };

    useEffect(() => {
        if (isFormOpen) {
            document.body.style.overflow = 'hidden'; // Disable body scroll when modal is open
        } else {
            document.body.style.overflow = 'auto'; // Enable body scroll when modal is closed
        }
        // Cleanup function to ensure scroll is enabled when component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isFormOpen]);

    useEffect(() => {
        const loadProjectList = async () => {
            const data = await loadProjectLists();
            setProjectList(data.records);
        }
        loadProjectList();
    }, []);


    return (
        <>
            <Header />
            <Sidebar editProfile='active' id="sidebar" />
            <div id="main" className={`min-h-screen bg-gray-100 flex flex-col transition-all ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
                <div className="p-4 sm:p-8 lg:px-12 flex-1">

                    <div className='flex justify-between items-center py-4 mt-2 mb-6'>
                        <h2 className='text-3xl font-bold text-blue-600'>Projects</h2>

                        <div className="flex items-center space-x-4">

                            <form action="" className="relative hidden md:block w-full max-w-md">
                                <input
                                    type="search"
                                    placeholder="Search projects..."
                                    className="w-full py-2 px-4 rounded-full shadow-sm bg-blue-50 border border-blue-200 placeholder-gray-400 text-gray-700 outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600 transition-all"
                                />
                                <button
                                    type="submit"
                                    className="absolute right-3 top-2.5 text-gray-500 hover:text-blue-600"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-4.35-4.35M11 18a7 7 0 100-14 7 7 0 000 14z" />
                                    </svg>
                                </button>
                            </form>

                            <button
                                onClick={toggleForm}
                                className="flex items-center bg-blue-600 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-700 transition-all whitespace-nowrap">
                                <svg
                                    className="w-5 h-5 mr-2"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 4v16m8-8H4"
                                    />
                                </svg>
                                Add Project
                            </button>
                        </div>
                    </div>

                    <hr className='border-t border-gray-300 mb-6' />

                    {isFormOpen ? (
                        <div className="fixed inset-0 z-50 flex items-center justify-center">
                            <div
                                className="fixed inset-0 bg-gray-800 bg-opacity-70"
                                onClick={closeForm}
                            ></div>

                            <div className="relative bg-white p-8 rounded-lg shadow-2xl w-full h-full max-w-full max-h-full overflow-y-auto z-10 transition-transform transform duration-300">
                                <h3 className='text-3xl font-bold text-blue-600 mb-6'>Add New Project</h3>

                                <button
                                    onClick={closeForm}
                                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition"
                                >
                                    <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>

                                <ProjectForm closeForm={closeForm} />
                            </div>
                        </div>
                    ) : (
                        <div className="gap-6">
                            {projectList.map((project) => (
                                <React.Fragment key={project.id}>
                                    <Projectcard project={project} />
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Project;
