import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userDetails: null,
        token: null,
    },
    reducers: {
        loginUser(state, action) {
            
            state.userDetails = action.payload.userDetails; 
            state.token = action.payload.token;
        },
        logoutUser(state) {
            state.userDetails = null;
            state.token = null;
        },
    },
});

export const { loginUser, logoutUser } = authSlice.actions;
export default authSlice.reducer;
