// locations.js

const locations = {
    "Andhra Pradesh": [
      "Amaravati",
      "Visakhapatnam",
      "Vijayawada",
      "Tirupati",
      "Guntur",
      "Nellore",
      "Kakinada",
      "Rajahmundry",
    ],
    "Arunachal Pradesh": [
      "Itanagar",
      "Naharlagun",
      "Pasighat",
      "Tezpur",
      "Ziro",
    ],
    Assam: [
      "Guwahati",
      "Dibrugarh",
      "Silchar",
      "Nagaon",
      "Tinsukia",
    ],
    Bihar: [
      "Patna",
      "Gaya",
      "Bhagalpur",
      "Muzaffarpur",
      "Purnea",
    ],
    Chhattisgarh: [
      "Raipur",
      "Bilaspur",
      "Durg",
      "Korba",
      "Rajnandgaon",
    ],
    Goa: [
      "Panaji",
      "Margao",
      "Mapusa",
      "Ponda",
      "Vasco da Gama",
    ],
    Gujarat: [
      "Ahmedabad",
      "Surat",
      "Vadodara",
      "Rajkot",
      "Gandhinagar",
      "Bhavnagar",
      "Jamnagar",
      "Junagadh",
      "Anand",
      "Nadiad",
    ],
    Haryana: [
      "Chandigarh",
      "Faridabad",
      "Gurugram",
      "Rohtak",
      "Panipat",
    ],
    HimachalPradesh: [
      "Shimla",
      "Dharamshala",
      "Kullu",
      "Manali",
      "Solan",
    ],
    Jharkhand: [
      "Ranchi",
      "Jamshedpur",
      "Dhanbad",
      "Bokaro Steel City",
      "Deoghar",
    ],
    Karnataka: [
      "Bangalore",
      "Mysore",
      "Mangalore",
      "Hubli",
      "Dharwad",
    ],
    Kerala: [
      "Thiruvananthapuram",
      "Kochi",
      "Kozhikode",
      "Kollam",
      "Malappuram",
    ],
    MadhyaPradesh: [
      "Bhopal",
      "Indore",
      "Gwalior",
      "Jabalpur",
      "Ujjain",
    ],
    Maharashtra: [
      "Mumbai",
      "Pune",
      "Nagpur",
      "Nashik",
      "Aurangabad",
    ],
    Manipur: [
      "Imphal",
      "Churachandpur",
      "Thoubal",
      "Bishnupur",
      "Ukhrul",
    ],
    Meghalaya: [
      "Shillong",
      "Tura",
      "Jowai",
      "Nongstoin",
      "Williamnagar",
    ],
    Mizoram: [
      "Aizawl",
      "Lunglei",
      "Siaha",
      "Champhai",
      "Kolasib",
    ],
    Nagaland: [
      "Kohima",
      "Dimapur",
      "Mokokchung",
      "Wokha",
      "Mon",
    ],
    Odisha: [
      "Bhubaneswar",
      "Cuttack",
      "Berhampur",
      "Sambalpur",
      "Rourkela",
    ],
    Punjab: [
      "Chandigarh",
      "Ludhiana",
      "Amritsar",
      "Jalandhar",
      "Patiala",
    ],
    Rajasthan: [
      "Jaipur",
      "Jodhpur",
      "Udaipur",
      "Ajmer",
      "Bikaner",
    ],
    Sikkim: [
      "Gangtok",
      "Namchi",
      "Gyalshing",
      "Mangan",
      "Pelling",
    ],
    TamilNadu: [
      "Chennai",
      "Coimbatore",
      "Madurai",
      "Tiruchirappalli",
      "Salem",
    ],
    Telangana: [
      "Hyderabad",
      "Warangal",
      "Nizamabad",
      "Khammam",
      "Karimnagar",
    ],
    Tripura: [
      "Agartala",
      "Ambassa",
      "Dharmanagar",
      "Udaipur",
      "Kailashahar",
    ],
    UttarPradesh: [
      "Lucknow",
      "Kanpur",
      "Ghaziabad",
      "Agra",
      "Varanasi",
    ],
    Uttarakhand: [
      "Dehradun",
      "Haridwar",
      "Nainital",
      "Rudrapur",
      "Roorkee",
    ],
    "West Bengal": [
      "Kolkata",
      "Siliguri",
      "Durgapur",
      "Asansol",
      "Howrah",
    ],
    "Jammu and Kashmir": [
      "Srinagar",
      "Jammu",
      "Anantnag",
      "Baramulla",
      "Kupwara",
    ],
    Ladakh: [
      "Leh",
      "Kargil",
    ],
    "Andaman and Nicobar Islands": [
      "Port Blair",
      "Diglipur",
      "Havelock Island",
    ],
    "Dadra and Nagar Haveli and Daman and Diu": [
      "Daman",
      "Diu",
      "Silvassa",
    ],
  };
  
  export default locations;
  