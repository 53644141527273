import React from 'react'
export default function Sidebar(props) {

    return(
        <>
            <div className="fixed top-16 transition-all overflow-hidden left-0 w-64 bg-white border-r border-gray-200 bottom-0 sidebar-collapse z-40 shadow-2xl shadow-indigo-500/50" id="sidebar">
                <a href="/Home/Dashboard" className="p-4 flex items-center gap-4 hover:bg-blue-50">
                    <img src="https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8bWFufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" className="w-16 aspect-square object-cover rounded" alt="" />
                    <div className="whitespace-nowrap sidebar-user-profile">
                        <h3 className="text-lg font-semibold mb-1">Yash Upadhyay</h3>
                        <span className="py-1 px-2 rounded-full bg-blue-600 text-white text-sm font-medium">Developer</span>
                    </div>
                </a>
                <div className="py-4">
                    <span className="text-sm text-gray-500 uppercase ml-4 inline-block mb-2 sidebar-menu-title">Menu</span>
                    <ul className="sidebar-menu">
                        <li>
                            <a href="/Home/Dashboard" className={props.dashboard}>
                                <i className='bx bxs-dashboard sidebar-menu-icon'></i>
                                Dashboard
                            </a>
                        </li>
                        <li>
                            <a href="/Home/EditProfile" className={props.editProfile}>
                                <i className='bx bx-user-circle sidebar-menu-icon'></i>
                                Edit Profile
                            </a>
                        </li>
                        <li>
                            <a href="/Home/Companies" className={props.companies}>
                                <i className='bx bxs-component sidebar-menu-icon'></i>
                                Companies
                            </a>
                        </li>
                        <li>
                            <a href="/Home/Result" className={props.result}>
                                <i className='bx bx-heart sidebar-menu-icon' ></i>
                                Result
                            </a>
                        </li>
                        <li>
                            <a href="/Home/Calender" className={props.calender}>
                                <i className='bx bxs-calendar sidebar-menu-icon'></i>
                                Calender
                            </a>
                        </li>
                        <li>
                            <a href="/Home/Setting" className={props.setting}>
                                <i className='bx bx-cog sidebar-menu-icon' ></i>
                                Settings
                            </a>
                        </li>
                        <li>
                            <a href="Logout">
                                <i className='bx bx-log-out sidebar-menu-icon' ></i>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}