import React from 'react'

export default function Header() {
  return (
    <>
        <nav className="h-16 py-1 px-4 shadow-md sticky top-0 left-0 z-50 backdrop-blur-xl bg-white/30">
            <div className="flex items-center h-full gap-12">
                <i className="bx bx-menu text-2xl cursor-pointer toggle-sidebar" onClick={window['slidebarHandle']}></i>
                <a href="#" className="flex items-center gap-4">
                    <span className="w-12 aspect-square flex items-center justify-center text-white text-2xl bg-blue-600 rounded-full">
                        <i className='bx bxl-pinterest'></i>
                    </span>
                    <span className="text-2xl font-semibold">InfyRealty</span>
                </a>
                <form action="" className="flex-1 max-w-lg hidden md:block">
                    <input type="search" placeholder="Search..." className="w-full py-2 px-4 rounded bg-gray-100 border border-gray-200 outline-none focus:ring-4 focus:ring-blue-100 focus:border-blue-600" />
                </form>
                {/* <a href="#" className="ml-auto relative text-gray-500 hover:text-gray-800 hidden md:block">
                    <i className='bx bx-envelope text-2xl' ></i>
                    <span className="absolute -top-1 w-2 h-2 rounded-full bg-red-600 left-1/2 -translate-x-1/2"></span>
                </a>
                <a href="#" className="relative text-gray-500 hover:text-gray-800 ml-auto md:ml-0">
                    <i className='bx bx-bell text-2xl' ></i>
                    <span className="absolute -top-1 w-2 h-2 rounded-full bg-red-600 left-1/2 -translate-x-1/2"></span>
                </a> */}
            </div>
        </nav>
    </>
  )
}
