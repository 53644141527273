import React, { useState } from 'react';
import { createProject } from '../../Services/ProjectService';
export default function ProjectForm({ closeForm }) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    developerName: '',
    projectName: '',
    email: '',
    phoneNumber: '',
    projectManager: '',
    country: '',
    address1: '',
    locality: '',
    city: '',
    state: '',
    zipCode: '',
    projectType: '',
    buildingType: '',
    buildingUse: '',
    totalTowers: '',
    wingsInTower: '',
    totalFlatsInWing: '',
    flatType: '',
    totalFloors: '',
    shopFloors: '',
    numberOfShops: '',
    numberOfOffice: '',
    totalBungalows: '',
    totalVillas: '',
    tags: ['cast', 'flatFacing', 'balcony', 'pent', 'penthouse'],
    customTags: [],
    selectedTags: [],
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const token = localStorage.getItem('token');
  // const userDetails = useSelector((state) => state.auth.userDetails);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevState) => {
        const updatedTags = checked
          ? [...prevState.selectedTags, value]
          : prevState.selectedTags.filter(tag => tag !== value);
        return { ...prevState, selectedTags: updatedTags };
      });
    } else if (name === 'customTag') {
      setFormData((prevState) => ({ ...prevState, customTag: value }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handlePhotoChange = (event) => {
    setSelectedImages(event.target.files);
  };
  const handleVideoChange = (event) => {
    setSelectedVideos(event.target.files);
  };
  const handleDocumentChange = (event) => {
    setSelectedDocuments(event.target.files);
  };

  const handleAddTag = () => {
    setFormData((prevState) => ({
      ...prevState,
      tags: [...prevState.tags, prevState.customTag],
      customTag: '',
    }));

  };

  const validate = () => {
    let formErrors = {};

    if (step === 1) {
      if (!formData.projectName) formErrors.projectName = 'Project Name is required';
      if (!formData.email) formErrors.email = 'Email is required';
      if (!formData.phoneNumber) formErrors.phoneNumber = 'Phone Number is required';
      if (!formData.projectManager) formErrors.projectManager = 'Project Manager is required';
    }

    if (step === 2) {
      if (!formData.country) formErrors.country = 'Country is required';
      if (!formData.address1) formErrors.address1 = 'Address is required';
      if (!formData.city) formErrors.city = 'City is required';
      if (!formData.state) formErrors.state = 'State is required';
      if (!formData.zipCode) formErrors.zipCode = 'Zip Code is required';
    }

    // if (step === 3) {
    //   if (!formData.projectType) formErrors.projectType = 'Project Type is required';
    //   if (!formData.buildingType) formErrors.buildingType = 'Building Type is required';
    //   if (!formData.totalTowers) formErrors.totalTowers = 'Total Towers is required';
    //   if (!formData.totalBungalows) formErrors.totalbunglows = 'Total Bungalows is required';
    //   if (!formData.totalVillas) formErrors.totalVillas = 'Total Villas is required';
    // }

    // if (step === 4) {
    //   if (!formData.totalFloors) formErrors.totalFloors = 'Total Floors is required';
    //   if (!formData.numberOfShops) formErrors.numberOfShops = 'Number of Shops is required';
    // }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const nextStep = () => {
    if (validate()) setStep(step + 1);
  };

  const submitForm = async () => {
    try {
      const response = await createProject(formData,selectedImages,selectedVideos,selectedDocuments, token);
      if(response.alert === 'info')
      {
        closeForm();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const previousStep = () => setStep(step - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
    }
  };
  return (
    <div className="flex flex-col w-full max-w-4xl mx-auto p-6 sm:p-8 shadow-lg bg-white rounded-lg">
      {/* Progress Bar */}
      <ol className="flex flex-wrap items-center w-full text-sm text-gray-500 font-medium sm:text-base mb-8 sm:mb-12">
        {['Project Info', 'Address', 'Project Details'].map((label, index) => (
          <li
            key={label}
            className={`flex items-center ${step > index ? 'text-indigo-600' : 'text-gray-600'} relative group sm:flex-nowrap flex-wrap mb-4`}
          >
            <span className={`w-8 h-8 ${step > index ? 'bg-indigo-600 text-white' : 'bg-gray-200'} border rounded-full flex justify-center items-center mr-3`}>
              {index + 1}
            </span>
            {label}
            {step > index && (
              <svg className="absolute -right-8 sm:right-[-32px] w-6 h-6 text-indigo-600 group-hover:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            )}
          </li>
        ))}
      </ol>

      {/* Step Forms */}
      <form onSubmit={handleSubmit} className="space-y-8">
        {step === 1 && (
          <>
            {/* Project Info Step */}
            <div className="grid grid-cols-2 gap-x-6">
              <div className="relative">
                <label className="text-sm font-medium text-gray-600">Project Name</label>
                <input
                  type="text"
                  name="projectName"
                  value={formData.projectName}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Project Name"
                />
                {errors.projectName && <p className="text-red-500 text-sm">{errors.projectName}</p>}
              </div>
              <div className="relative">
                <label className="text-sm font-medium text-gray-600">Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Email"
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
            </div>
            <div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                placeholder="Enter Phone Number"
              />
              {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
            </div>
            <div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Project Manager</label>
              <input
                type="text"
                name="projectManager"
                value={formData.projectManager}
                onChange={handleChange}
                className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                placeholder="Enter Project Manager"
              />
              {errors.projectManager && <p className="text-red-500 text-sm">{errors.projectManager}</p>}
            </div>
            <div className="mb-6 space-y-8">
              <div className="relative">
                <label className="text-sm font-medium text-gray-600 mb-2 block">Photos</label>
                <div className="flex items-center justify-center w-full">
                  <label className="flex w-full flex-col items-center px-4 py-6 bg-white text-indigo-600 rounded-lg shadow-md tracking-wide uppercase border border-indigo-500 cursor-pointer hover:bg-indigo-600 hover:text-white transition-all duration-150">
                    <svg
                      className="w-10 h-10"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.88 3H7.12C5.95 3 5 3.95 5 5.12v13.75C5 20.05 5.95 21 7.12 21h9.75c1.17 0 2.13-.95 2.13-2.13V5.12C19 3.95 18.05 3 16.88 3zM7.12 5h9.75c.07 0 .13.06.13.13v10.73l-2.56-2.56a1.72 1.72 0 00-2.44 0L7.12 18.25V5.13c0-.07.06-.13.13-.13zM12 16.8L16.88 21H7.12l4.88-4.88zm4.13-10.88l-.88-.88H8.75l-.88.88v7.13H6.12V5.13c0-.07.06-.13.13-.13h11.5c.07 0 .13.06.13.13v7.13h-1.75V5.92z"></path>
                    </svg>
                    <span className="mt-2 text-base leading-normal">Select photos</span>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handlePhotoChange}
                      className="hidden"
                    />
                  </label>
                </div>
                {selectedImages.length > 0 && (
                  <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    <strong>Selected files:</strong> {selectedImages[0].name}...
                  </div>
                )}
              </div>

              {/* Videos Upload */}
              <div className="relative">
                <label className="text-sm font-medium text-gray-600 mb-2 block">Videos</label>
                <div className="flex items-center justify-center w-full">
                  <label className="flex w-full flex-col items-center px-4 py-6 bg-white text-indigo-600 rounded-lg shadow-md tracking-wide uppercase border border-indigo-500 cursor-pointer hover:bg-indigo-600 hover:text-white transition-all duration-150">
                    <svg
                      className="w-10 h-10"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M15 3H5C3.9 3 3 3.9 3 5v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14l-4.2-3-4.8 3V5h9v12zM13 7v8l-5.4-3.5L13 7z"></path>
                    </svg>
                    <span className="mt-2 text-base leading-normal">Select videos</span>
                    <input
                      type="file"
                      name="videos"
                      multiple
                      accept="video/*"
                      onChange={handleVideoChange}
                      className="hidden"
                    />
                  </label>
                </div>
                {selectedVideos.length > 0 && (
                  <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    <strong>Selected files:</strong> {selectedVideos[0].name}...
                  </div>
                )}
              </div>

              {/* Documents Upload */}
              <div className="relative">
                <label className="text-sm font-medium text-gray-600 mb-2 block">Documents</label>
                <div className="flex items-center justify-center w-full">
                  <label className="flex w-full flex-col items-center px-4 py-6 bg-white text-indigo-600 rounded-lg shadow-md tracking-wide uppercase border border-indigo-500 cursor-pointer hover:bg-indigo-600 hover:text-white transition-all duration-150">
                    <svg
                      className="w-10 h-10"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8-8-3.58 8-8 8zm-1-13h2v5h-2zm0 6h2v2h-2z"></path>
                    </svg>
                    <span className="mt-2 text-base leading-normal">Select documents</span>
                    <input
                      type="file"
                      name="documentAttachment"
                      onChange={handleDocumentChange}
                      className="hidden"
                    />
                  </label>
                </div>
                {selectedDocuments.length > 0 && (
                  <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    <strong>Selected files:</strong> {selectedDocuments[0].name}...
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {/* Address Step */}
        {step === 2 && (
          <>
            <div className="grid grid-cols-2 gap-x-6">
              <div className="relative">
                <label className="text-sm font-medium text-gray-600">Country</label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Country"
                />
                {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
              </div>
              <div className="relative">
                <label className="text-sm font-medium text-gray-600">Address 1</label>
                <input
                  type="text"
                  name="address1"
                  value={formData.address1}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Address 1"
                />
                {errors.address1 && <p className="text-red-500 text-sm">{errors.address1}</p>}
              </div>
            </div>

            <div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Locality</label>
              <input
                type="text"
                name="locality"
                value={formData.locality}
                onChange={handleChange}
                className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                placeholder="Enter Locality"
              />
              {errors.locality && <p className="text-red-500 text-sm">{errors.locality}</p>}
            </div>

            {/* Additional Fields: City, State, Zip Code */}
            <div className="grid grid-cols-2 gap-x-6">
              <div className="relative">
                <label className="text-sm font-medium text-gray-600">City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter City"
                />
                {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
              </div>
              <div className="relative">
                <label className="text-sm font-medium text-gray-600">State</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter State"
                />
                {errors.state && <p className="text-red-500 text-sm">{errors.state}</p>}
              </div>
            </div>

            <div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Zip Code</label>
              <input
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                placeholder="Enter Zip Code"
              />
              {errors.zipCode && <p className="text-red-500 text-sm">{errors.zipCode}</p>}
            </div>
          </>

        )}

        {step === 3 && (
          <>
            {/* Project Type (Commercial/Residential) */}
            <div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Project Type</label>
              <select
                name="projectType"
                value={formData.projectType}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="">Select Project Type</option>
                <option value="commercial">Commercial</option>
                <option value="residential">Residential</option>
              </select>
              {errors.projectType && <p className="text-red-500 text-sm mt-1">{errors.projectType}</p>}
            </div>

            {/* Building Type (High Rise/Low Rise) */}
            {formData.projectType == 'residential' && <div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Building Type</label>
              <select
                name="buildingType"
                value={formData.buildingType}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="">Select Building Type</option>
                <option value="highrise">High Rise</option>
                <option value="lowrise">Low Rise</option>
              </select>
              {errors.buildingType && <p className="text-red-500 text-sm mt-1">{errors.buildingType}</p>}
            </div>}

            {/* Building Use (Mix or Flats Only) */}
            {(formData.buildingType === 'highrise' && formData.projectType === 'residential') && (
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Building Use</label>
                <select
                  name="buildingUse"
                  value={formData.buildingUse}
                  onChange={handleChange}
                  className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                >
                  <option value="">Select Building Use</option>
                  <option value="mix">Mix (Shop and Flats)</option>
                  <option value="flatsOnly">Flats Only</option>
                </select>
                {errors.buildingUse && <p className="text-red-500 text-sm mt-1">{errors.buildingUse}</p>}
              </div>
            )}

            {(formData.buildingType == 'lowrise' && formData.projectType == 'residential') && (<div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Building Use</label>
              <select
                name="buildingUse"
                value={formData.buildingUse}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="">Select Building Use</option>
                <option value="mixBungalowsAndVillas">Mix (Bungalows and Villas)</option>
                <option value="bungalowsOnly">Bungalows Only</option>
                <option value="villasOnly">Villas Only</option>
              </select>
              {errors.buildingUse && <p className="text-red-500 text-sm mt-1">{errors.buildingUse}</p>}
            </div>)}

            {(formData.projectType === 'commercial') && (
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Building Use</label>
                <select
                  name="buildingUse"
                  value={formData.buildingUse}
                  onChange={handleChange}
                  className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                >
                  <option value="">Select Building Use</option>
                  <option value="mixShopOffice">Mix (Shop and Offices)</option>
                  <option value="shopOnly">Shops Only</option>
                  <option value="officeOnly">Offcies Only</option>
                </select>
                {errors.buildingUse && <p className="text-red-500 text-sm mt-1">{errors.buildingUse}</p>}
              </div>
            )}

            {/* Total Towers */}
            {(formData.buildingType === 'highrise' || formData.projectType === 'commercial') && (<div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Total Towers</label>
              <input
                type="number"
                name="totalTowers"
                value={formData.totalTowers}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                placeholder="Enter Total Towers"
              />
              {errors.totalTowers && <p className="text-red-500 text-sm mt-1">{errors.totalTowers}</p>}
            </div>)}

            {/* Wings in Tower */}
            {(formData.buildingType === 'highrise' || formData.projectType === 'commercial') && (<div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Wings in Tower</label>
              <input
                type="number"
                name="wingsInTower"
                value={formData.wingsInTower}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                placeholder="Enter Wings in Tower"
              />
              {errors.wingsInTower && <p className="text-red-500 text-sm mt-1">{errors.wingsInTower}</p>}
            </div>)}

            {/* Total Flats in Wing */}
            {formData.buildingType === 'highrise' && <div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Total Flats in Wing</label>
              <input
                type="number"
                name="totalFlatsInWing"
                value={formData.totalFlatsInWing}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                placeholder="Enter Total Flats in Wing"
              />
              {errors.totalFlatsInWing && <p className="text-red-500 text-sm mt-1">{errors.totalFlatsInWing}</p>}
            </div>}

            {(formData.buildingType === 'lowrise' && (formData.buildingUse === 'mixBungalowsAndVillas' || formData.buildingUse === 'bungalowsOnly')) && (<div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Total Bungalows</label>
              <input
                type="number"
                name="totalBungalows"
                value={formData.totalBungalows}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                placeholder="Enter Total bunglaows"
              />
              {errors.totalbunglows && <p className="text-red-500 text-sm mt-1">{errors.totalFlatsInWing}</p>}
            </div>)}

            {(formData.buildingType === 'lowrise' && (formData.buildingUse === 'mixBungalowsAndVillas' || formData.buildingUse === 'villasOnly')) && (<div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Total Villas</label>
              <input
                type="number"
                name="totalFlatsInWing"
                value={formData.totalVillas}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                placeholder="Enter Total Villas"
              />
              {errors.totalVillas && <p className="text-red-500 text-sm mt-1">{errors.totalFlatsInWing}</p>}
            </div>)}

            {/* Flat Type (2BHK, 3BHK, 4BHK, Mix) */}
            {formData.buildingType === 'highrise' && <div className="relative mb-6">
              <label className="text-sm font-medium text-gray-600">Flat Type</label>
              <select
                name="flatType"
                value={formData.flatType}
                onChange={handleChange}
                className="block w-full h-12 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <option value="">Select Flat Type</option>
                <option value="2bhk">2BHK</option>
                <option value="3bhk">3BHK</option>
                <option value="4bhk">4BHK</option>
                <option value="mix">Mix</option>
              </select>
              {errors.flatType && <p className="text-red-500 text-sm mt-1">{errors.flatType}</p>}
            </div>}

            <div>
              {(formData.buildingType === 'highrise' || formData.projectType === 'commercial') && (<div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Total Floors</label>
                <input
                  type="number"
                  name="totalFloors"
                  value={formData.totalFloors}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Total Floors"
                />
              </div>)}

              {(formData.buildingUse == 'mix') && (<div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Shop Floors</label>
                <input
                  type="number"
                  name="shopFloors"
                  value={formData.shopFloors}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Shop Floors"
                />
              </div>)}
              {/* Number of Shops */}
              {(formData.buildingUse == 'mixShopOffice' || formData.buildingUse == 'shopOnly') && (<div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Number of Shops</label>
                <input
                  type="number"
                  name="numberOfShops"
                  value={formData.numberOfShops}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Number of Shops"
                />
              </div>)}

              {(formData.buildingUse == 'mixShopOffice' || formData.buildingUse == 'officeOnly') && (<div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Number of Office</label>
                <input
                  type="number"
                  name="numberOfOffice"
                  value={formData.numberOfOffice}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Number of Office"
                />
              </div>)}

              {/* Tags */}
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600 mb-2 block">Tags</label>
                <div className="flex flex-wrap gap-3">
                  {formData.tags.map(tag => (
                    <div key={tag} className="flex items-center">
                      <input
                        type="checkbox"
                        id={tag}
                        value={tag}
                        checked={formData.selectedTags.includes(tag)}
                        onChange={handleChange}
                        className="w-5 h-5 border-gray-300 rounded-full bg-white checked:bg-blue-500 checked:border-transparent focus:ring-2 focus:ring-blue-500"
                      />
                      <label
                        htmlFor={tag}
                        className="ml-2 text-sm text-gray-700 cursor-pointer"
                      >
                        {tag}
                      </label>
                    </div>
                  ))}
                </div>
              </div>


              {/* Add Custom Tag */}
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Add Custom Tag</label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    name="customTag"
                    value={formData.customTag}
                    onChange={handleChange}
                    className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                    placeholder="Enter Custom Tag"
                  />
                  <button
                    type="button"
                    onClick={handleAddTag}
                    className="bg-blue-500 text-white px-4 py-2 rounded-full"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </>


        )}

        {/* {step === 4 && (
          <>
            <div>
             
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Total Floors</label>
                <input
                  type="number"
                  name="totalFloors"
                  value={formData.totalFloors}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Total Floors"
                />
              </div>
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Shop Floors</label>
                <input
                  type="number"
                  name="shopFloors"
                  value={formData.shopFloors}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Shop Floors"
                />
              </div>
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Number of Shops</label>
                <input
                  type="number"
                  name="numberOfShops"
                  value={formData.numberOfShops}
                  onChange={handleChange}
                  className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                  placeholder="Enter Number of Shops"
                />
              </div>
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600 mb-2 block">Tags</label>
                <div className="flex flex-wrap gap-3">
                  {formData.tags.map(tag => (
                    <div key={tag} className="flex items-center">
                      <input
                        type="checkbox"
                        id={tag}
                        value={tag}
                        checked={formData.selectedTags.includes(tag)}
                        onChange={handleChange}
                        className="w-5 h-5 border-gray-300 rounded-full bg-white checked:bg-blue-500 checked:border-transparent focus:ring-2 focus:ring-blue-500"
                      />
                      <label
                        htmlFor={tag}
                        className="ml-2 text-sm text-gray-700 cursor-pointer"
                      >
                        {tag}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="relative mb-6">
                <label className="text-sm font-medium text-gray-600">Add Custom Tag</label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    name="customTag"
                    value={formData.customTag}
                    onChange={handleChange}
                    className="block w-full h-11 px-5 py-2.5 border-gray-300 border rounded-full"
                    placeholder="Enter Custom Tag"
                  />
                  <button
                    type="button"
                    onClick={handleAddTag}
                    className="bg-blue-500 text-white px-4 py-2 rounded-full"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </>
        )} */}

        <div className="flex justify-between items-center mt-8">
          {step > 1 && (
            <button
              type="button"
              onClick={previousStep}
              className="px-8 py-3 text-base font-medium text-gray-600 bg-gray-200 rounded-full hover:bg-gray-300"
            >
              Previous
            </button>
          )}
          {step < 3 ? (
            <button
              type="button"
              onClick={nextStep}
              className="px-8 py-3 text-base font-medium text-white bg-indigo-600 rounded-full hover:bg-indigo-700"
            >
              Next
            </button>
          ) : (
            <button
              type="submit"
              className="px-8 py-3 text-base font-medium text-white bg-indigo-600 rounded-full hover:bg-indigo-700"
              onClick={submitForm}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
