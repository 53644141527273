import apiClient from "./axiosInstance";

// const API_URL = 'http://127.0.0.1:8080/bm_project/create';

export const createProject = async (formData,photos,videos,documents, token) => {
  const headers = {
    Authorization: token,
    "Content-Type": "multipart/form-data",
  };

  const data = new FormData();
  data.append("name", formData.projectName);
  data.append("email", formData.email);
  data.append("contact_code", "+91");
  data.append("contact_no", formData.phoneNumber);
  data.append("address1", formData.address1);
  data.append("locality", formData.locality);
  data.append("city", formData.city);
  data.append("state", formData.state);
  data.append("country", formData.country);
  data.append("zipcode", formData.zipCode);
  data.append("project_type", formData.projectType);
  data.append("building_type", formData.buildingType);
  data.append("building_used", formData.buildingUse);
  data.append("total_towers", formData.totalTowers || 0);
  data.append("wings_in_towers", parseInt(formData.wingsInTower) || 0);
  data.append("floors_in_towers", parseInt(formData.totalFloors) || 0);
  data.append("flats", parseInt(formData.totalFlatsInWing) || 0);
  data.append("flat_type", formData.flatType || 'Mix' );
  data.append("shops_floor_in_towers", parseInt(formData.shopFloors) || 0);
  data.append("shops", parseInt(formData.numberOfShops) || 0);
  data.append("offices", parseInt(formData.numberOfOffice) || 0);
  data.append("bungalows", parseInt(formData.totalBungalows) || 0);
  data.append("villas", parseInt(formData.totalVillas) || 0);
  //data.append('tags', formData.selectedTags.join(','));
  data.append("tags", JSON.stringify([]));
  if (photos) {
    Array.from(photos).forEach((file) => {
      data.append("files", file);
    });
  }

  if (videos) {
    Array.from(videos).forEach((file) => {
      data.append("files", file);
    });
  }
  if (documents) {
    Array.from(documents).forEach((file) => {
      data.append("files", file);
    });
  }

  try {
    const response = await apiClient.post("bm_project/create", data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};
export const loadProjectLists = async () => {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: token,
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await apiClient.get("bm_project/get_project_list", {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching project list:", error);
    throw error;
  }
};


