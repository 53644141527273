import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imageArrow from './../Assets/Images/imageArrow.png'
import imageDashboard from './../Assets/Images/dashbord.png'
import trackRecord from './../Assets/Images/trackRecord.png'
import security from './../Assets/Images/security.png'
import paperwork from './../Assets/Images/paperwork.png'
import mainContent from './../Assets/Images/maincontent.png'
import CustomSpinner from '../Components/CustomSpinner';

function LandingPage() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dropdownRef = useRef(null);

    // Function to show toast
    const showToast = (message = "Hello", type = "error") => {
        setLoading(!loading);
        toast(message + " module in under process", {
            type,
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            autoClose: 3000,
            hideProgressBar: false,
        });
        setTimeout(()=>{
            setLoading(false)
        }, 2000)
    };

    // Click handlers
    const handleClick = (section) => {
        showToast(section);
        setDropdownOpen(false); // Close dropdown after item click
    };

    // Array of navigation items
    const navItems = [
        { name: 'Home', handler: () => handleClick('Home') },
        { name: 'Marketplace', handler: () => handleClick('Marketplace') },
        { name: 'Features', handler: () => handleClick('Features') },
        { name: 'Team', handler: () => handleClick('Team') },
        { name: 'Contact', handler: () => handleClick('Contact') },
    ];

    // Function to render navigation links
    const renderNavLinks = () => {
        return (
            <ul className="list-none p-0 m-0 flex flex-col lg:flex-row lg:space-x-4">
            {navItems.map((item, index) => (
                <li key={index}>
                    <a
                        href="#"
                        onClick={item.handler}
                        className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                    >
                        {item.name}
                    </a>
                </li>
            ))}
        </ul>
        )
    };

    function LandingPageHeader() {
        // Effect to handle clicks outside of dropdown
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setDropdownOpen(false);
                }
            };

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Cleanup the event listener
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <div className="pb-10 bg-landing-background-color">
                {/* Header Block */}
                <header className="fixed top-0 left-0 right-0 z-50 bg-landing-background-color">
                    <nav className="border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
                        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                            <a href="#" className="flex items-center">
                                <img src="https://flowbite.com/docs/images/logo.svg" className="mr-3 h-6 sm:h-9" alt="Logo" />
                                <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">InfyRealty</span>
                            </a>

                            {/* NavLinks for Large Screen */}
                            <div className="hidden lg:flex lg:items-center lg:space-x-4">
                                {renderNavLinks()}
                            </div>

                            <div className="flex items-center lg:order-2">
                                <a href="login" className="text-blue-800 dark:text-white hover:bg-gray-50 font-semibold rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">
                                    Log in
                                    <svg className="inline-block w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                    </svg>
                                </a>
                                <button
                                    onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown
                                    className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                    aria-controls="mobile-menu-2"
                                    aria-expanded={dropdownOpen}
                                >
                                    <span className="sr-only">Toggle dropdown</span>
                                    {dropdownOpen ? (
                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    ) : (
                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </nav>
                </header>

                {/* NavBar for Small Dropdown Menu */}
                {dropdownOpen && (
                    <div ref={dropdownRef} className="absolute w-full bg-landing-background-color shadow-md z-40 mt-2 lg:hidden">
                        <ul className="flex flex-col">
                            {renderNavLinks()}
                        </ul>
                    </div>
                )}
        
                {/* Main */}
                <section className="h-screen flex items-center bg-landing-background-color pt-28 lg:pb-16 dark:bg-gray-900">
                    <div className="grid max-w-screen-xl justify-center px-4 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                        <div className="lg:mt-0 lg:col-span-5 lg:flex justify-center">
                            <img 
                                src={mainContent} 
                                alt="hero image" 
                                className="px-[20%] max-h-80 h-auto object-cover mr:col-span-2 lg:px-0 lg:w-auto mx-auto"
                            />
                        </div>
                        <div className="place-self-center lg:col-span-7 lg:px-10 text-center md:text-left">
                            <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl text-custom-text-header-dark dark:text-white">
                                BuildSmart :<br /> Simplify Projects.
                            </h1>
                            <p className="mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                                Let's Build something Great!
                            </p>
                            
                            {/* Container for image and button */}
                            <div className="flex flex-col md:flex-row items-center justify-center p-5">
                                <img 
                                    src={imageArrow} 
                                    alt="Description of the image" 
                                    className="w-8 h-auto md:w-16 mb-4 md:mb-0 md:mr-4"
                                />
                                <button className="px-4 py-2 bg-blue-700 text-white rounded-md shadow-md hover:bg-blue-600 transition">
                                    <a href="login" >
                                        Get Started
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Section Blogs */}
                <div className="px-100 lg:px-20">
                    {/* 1 */}
                    {/* Product Info Block Start With Left */}
                    <section className="m-10 md:p-5 lg:p-5 bg-white dark:bg-gray-900 border border-transparent lg:border-gray-200 rounded-lg lg:rounded-lg shadow-md">
                        <div className="grid max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-2">

                        {/* Image Block */}
                        <figure className="max-w-lg md:px-10 lg:px-10 mx-auto lg:col-span-5 lg:order-1 order-1">
                            <img className="h-60 max-w-full rounded-lg" src={paperwork} alt="image description" />
                        </figure>

                        {/* Text Block */}
                        <div className="mr-auto text-wrap lg:col-span-7 lg:order-2 order-2 lg:text-left">
                            <h1 className="max-w-xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-4xl xl:text-4xl dark:text-white">
                            Is paper work boring?
                            </h1>
                            <p className="max-w-2xl mb-6 font-light text-wrap text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                            <div className="text-wrap">
                                Forget the hassle of paperwork—we securely store and organize everything for you.
                            </div>
                            </p>
                        </div>

                        </div>
                    </section>

                    {/* 2 */}
                    {/* Product Info Block Start With Left */}
                    <section className="m-10 md:p-5 lg:p-5 bg-white dark:bg-gray-900 border border-transparent lg:border-gray-200 rounded-lg shadow-md">
                        <div className="grid max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-2">

                            {/* Image Block */}
                            <figure className="max-w-lg md:px-10 lg:px-10 mx-auto lg:col-span-5 lg:order-2 order-1">
                                <img className="h-auto max-w-full rounded-lg h-60" src={trackRecord} alt="image description" />
                            </figure>

                            {/* Text Block */}
                            <div className="mr-auto text-wrap lg:col-span-7 lg:order-1 order-2 lg:text-left">
                                <h1 className="max-w-xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-4xl xl:text-4xl dark:text-white">
                                    Track Record?
                                </h1>
                                <p className="max-w-2xl mb-6 font-light text-wrap text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                                    <div className="text-wrap">
                                        Access your data anytime, from anywhere, with real-time updates at your fingertips.
                                    </div>
                                </p>
                            </div>

                        </div>
                    </section>


                    {/* 3 */}
                    {/* Product Info Block Start With Left */}
                    <section className="m-10 md:p-5 lg:p-5 bg-white dark:bg-gray-900 border border-transparent lg:border-gray-200 rounded-lg lg:rounded-lg shadow-md">
                        <div className="grid max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-2">

                        {/* Image Block */}
                        <figure className="max-w-lg md:px-10 lg:px-10 mx-auto lg:col-span-5 lg:order-1 order-1">
                            <img className="h-60 max-w-full rounded-lg" src={imageDashboard} alt="image description" />
                        </figure>

                        {/* Text Block */}
                        <div className="mr-auto text-wrap lg:col-span-7 lg:order-2 order-2 lg:text-left">
                            <h1 className="max-w-xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-4xl xl:text-4xl dark:text-white">
                                Dashing Dashboard
                            </h1>
                            <p className="max-w-2xl mb-6 font-light text-wrap text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                            <div className="text-wrap">
                                Our intuitive dashboard gives you a clear view of progress, timelines, and data with a single glance.
                            </div>
                            </p>
                        </div>

                        </div>
                    </section>


                    {/* 4 */}
                    {/* Product Info Block Start With Right */}
                    <section className="m-10 md:p-5 lg:p-5 bg-white dark:bg-gray-900 border border-transparent lg:border-gray-200 rounded-lg shadow-md">
                        <div className="grid max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-2">

                            {/* Image Block */}
                            <figure className="max-w-lg md:px-10 lg:px-10 mx-auto lg:col-span-5 lg:order-2 order-1">
                                <img className="h-60 max-w-fit rounded-lg" src={security} alt="image description" />
                            </figure>

                            {/* Text Block */}
                            <div className="mr-auto text-wrap lg:col-span-7 lg:order-1 order-2 lg:text-left">
                                <h1 className="max-w-xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-4xl xl:text-4xl dark:text-white">
                                    Security? Ohoo...
                                </h1>
                                <p className="max-w-2xl mb-6 font-light text-wrap text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                                    <div className="text-wrap">
                                        All sensitive information is encrypted to protect it from unauthorized access.
                                        Only authorized users can view or modify your data, ensuring confidentiality.
                                    </div>
                                </p>
                            </div>

                        </div>
                    </section>
                </div>
                
            </div>
        );
    }

    return (
        <div className="relative"> {/* Relative position for the loader */}
            <LandingPageHeader />
            <ToastContainer />
            {loading && (
                <CustomSpinner />
            )}
        </div>
    );
}

export default LandingPage;
