import React, { useState } from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/SideBar';
import locations from '../static/location';
import { signUp, sendOTP, verifyOTP, login, getToken } from '../Services/AuthService';
import OtpPopup from '../Components/OTPComponent';
import { useDispatch } from 'react-redux';
import { loginUser } from '../features/Auth/authSlice';
import { useNavigate } from 'react-router-dom';
import CustomSpinner from '../Components/CustomSpinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoginPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [businessName, setbusinessName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('business');
  const [otpId, setOtpId] = useState(0);
  const cities = state ? locations[state] : [];
  const [loading, showLoading] = useState(false);

  const [logo, setLogo] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSignUp = async () => {
    const newErrors = {};

    // Validate fields
    if (!businessName) newErrors.businessName = 'Business name is required';
    if (!address) newErrors.address = 'Business Address is required';
    if (!zipCode) newErrors.zipCode = 'Zip code is required';
    if (!countryCode) newErrors.countryCode = 'Country code is required';
    if (!phoneNumber) newErrors.phoneNumber = 'Phone number is required';
    if (!email) newErrors.email = 'Email is required';
    if (!password) newErrors.password = 'Password is required';
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    } else if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }
    const formData = new FormData();
    formData.append('name', businessName);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('contact_code', countryCode);
    formData.append('contact_no', phoneNumber);
    formData.append('address1', address);
    formData.append('address2', '');
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zipcode', zipCode);
    if (logo) formData.append('logo', logo);

    try {
      const response = await signUp(formData);
      if (response.alert === 'info') {
        const formDataSendOTP = new FormData();
        formDataSendOTP.append('user_id', response.user_id);
        formDataSendOTP.append('email', response.email);


        const res = await sendOTP(formDataSendOTP);
        if (res.alert === 'info') {
          setOtpId(res.otp_id);
          setIsOtpVisible(true);
        }
      }
    } catch (error) {

    }
  };

  // Function to show toast
  const showToast = (message = "Hello", type = "error") => {
    toast(message + " module in under process", {
        type,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        autoClose: 3000,
        hideProgressBar: false,
    });
};

  const handleLogin = async () => {
    const newErrors = {};

    if (!email) newErrors.email = 'Email is required';
    if (!password) newErrors.password = 'Password is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    showLoading(true);

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    try {
      const res = await login(formData);
      if (res.alert === 'danger') {
        showToast(res.msg); // Show error message from response
      }else if (res.alert === 'info') {
        const token = res.jwtToken;
        localStorage.setItem('token', token);
        const tokenData = await getToken(token);
        localStorage.setItem('branchDetail', JSON.stringify(tokenData));
        dispatch(loginUser({ userDetails: tokenData, token }));
        navigate('/projects');
      }
    } catch (error) {
      console.error("Login failed", error);
    } finally {
      showLoading(false);
    }
  };

  const handleAccountSelect = (accountType) => {
    setSelectedAccount(accountType);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    setCity('');
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
  };
  const handleOtpSubmit = async (otp) => {
    const formData = new FormData();
    formData.append('otp', otp);
    formData.append('otp_id', otpId)
    const res = await verifyOTP(formData);
    if (res.alert == 'info') {
      setIsOtpVisible(false);
      setIsLogin(true);
    }
  };

  const inputClass = (field) =>
    `block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border ${errors[field] ? 'border-red-500' : 'border-gray-200'
    } rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40`;

  return (
    <>
      <div className="p-4">
        {loading && <CustomSpinner />}
        <section className="bg-white dark:bg-gray-900">
          <div className="flex justify-center min-h-screen">
            <div
              className="hidden bg-cover lg:block lg:w-2/5"
              style={{
                backgroundImage: "url('https://images.unsplash.com/photo-1494621930069-4fd4b2e24a11?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80')",
              }}
            ></div>

            <div className="flex items-center w-full max-w-3xl p-8 mx-auto lg:px-12 lg:w-3/5">
              <div className="w-full">
                <h1 className="text-2xl font-semibold tracking-wider text-gray-800 capitalize dark:text-white">
                  {isLogin ? "Log in to your account" : "Get your free account now."}
                </h1>

                <div className="mt-6">
                  {!isLogin ? (
                    <div className="mt-6">
                      <h1 className="text-gray-500 dark:text-gray-300">Select type of account</h1>
                      <div className="mt-3 md:flex md:items-center md:-mx-2">
                        {/* Account Type Buttons */}
                        <button
                          onClick={() => handleAccountSelect('business')}
                          className={`flex justify-center w-full px-6 py-3 text-white ${selectedAccount === 'business' ? 'bg-blue-500' : 'bg-gray-400'
                            } rounded-lg md:w-auto md:mx-2 focus:outline-none`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                          <span className="mx-2">Business</span>
                        </button>

                        <button
                          onClick={() => handleAccountSelect('personal')}
                          className={`flex justify-center w-full px-6 py-3 mt-4 text-white ${selectedAccount === 'personal' ? 'bg-blue-500' : 'bg-gray-400'
                            } rounded-lg md:mt-0 md:w-auto md:mx-2 focus:outline-none`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            />
                          </svg>
                          <span className="mx-2">Personal</span>
                        </button>
                      </div>
                    </div>
                  ) : null}

                  <button
                    onClick={() => setIsLogin(!isLogin)}
                    className="mt-6 px-6 py-3 text-sm text-blue-500 underline"
                  >
                    {isLogin ? "Create an account" : "Already have an account? Log in"}
                  </button>
                </div>

                <form className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-2">
                  {!isLogin ? (
                    <>
                      {/* Business Name Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Business Name</label>
                        <input
                          type="text"
                          value={businessName}
                          onChange={(e) => setbusinessName(e.target.value)}
                          placeholder="Avadhish Heights"
                          className={inputClass('businessName')}
                        />
                        {errors.businessName && <p className="text-red-500 text-sm mt-1">{errors.businessName}</p>}
                      </div>

                      {/* Business Address Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Business Address</label>
                        <input
                          type="text"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          placeholder="Vesu"
                          className={inputClass('address')}
                        />
                        {errors.address && <p className="text-red-500 text-sm mt-1">{errors.address}</p>}
                      </div>

                      {/* State Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">State</label>
                        <select
                          value={state}
                          onChange={handleStateChange}
                          className={inputClass('state')}
                        >
                          <option value="" disabled>Select State</option>
                          {Object.keys(locations).map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* City Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">City</label>
                        <select
                          value={city}
                          onChange={handleCityChange}
                          className={inputClass('city')}
                        >
                          <option value="" disabled>Select City</option>
                          {cities.map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Zip Code Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Zip Code</label>
                        <input
                          type="text"
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                          placeholder="395007"
                          className={inputClass('zipCode')}
                        />
                        {errors.zipCode && <p className="text-red-500 text-sm mt-1">{errors.zipCode}</p>}
                      </div>

                      {/* Country Code Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Country Code</label>
                        <input
                          type="text"
                          value={countryCode}
                          onChange={(e) => setCountryCode(e.target.value)}
                          placeholder="+91"
                          className={inputClass('countryCode')}
                        />
                        {errors.countryCode && <p className="text-red-500 text-sm mt-1">{errors.countryCode}</p>}
                      </div>

                      {/* Phone Number Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Phone Number</label>
                        <input
                          type="text"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="9876543210"
                          className={inputClass('phoneNumber')}
                        />
                        {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>}
                      </div>

                      {/* Email Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="john.doe@example.com"
                          className={inputClass('email')}
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                      </div>

                      {/* Password Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Password</label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="********"
                          className={inputClass('password')}
                        />
                        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                      </div>

                      {/* Confirm Password Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Confirm Password</label>
                        <input
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="********"
                          className={inputClass('confirmPassword')}
                        />
                        {errors.confirmPassword && <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>}
                      </div>
                      {/* Logo File Input */}
                      <div className="mt-4">
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Upload Logo</label>
                        <div className="relative flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-4 hover:border-blue-400 hover:bg-blue-50 dark:hover:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 transition duration-300 w-full">
                          <input
                            type="file"
                            accept="image/*"
                            id="logo-upload"
                            onChange={handleFileChange}
                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                          />
                          <div className="flex flex-col items-center space-y-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-12 h-12 text-gray-400 group-hover:text-blue-400 dark:text-gray-500 dark:group-hover:text-blue-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5V6a2 2 0 012-2h14a2 2 0 012 2v10.5M16 8.5l-4 4m0 0l-4-4m4 4V3.5"
                              />
                            </svg>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Drag and drop your logo, or <span className="text-blue-500 hover:underline">browse</span>
                            </p>
                          </div>
                        </div>
                        {logo && (
                          <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                            <strong>Selected file:</strong> {logo.name}
                          </div>
                        )}
                      </div>



                    </>
                  ) : (
                    <>
                      {/* Login Email Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="john.doe@example.com"
                          className={inputClass('email')}
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                      </div>

                      {/* Login Password Input */}
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Password</label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="********"
                          className={inputClass('password')}
                        />
                        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                      </div>
                    </>
                  )}
                </form>

                {/* Submit Button */}
                <div className="mt-6">
                  {!isLogin ? (
                    <button
                      onClick={handleSignUp}
                      className="w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
                    >
                      Sign Up
                    </button>
                  ) : (
                    <button
                      onClick={handleLogin}
                      className="w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
                    >
                      Log In
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <OtpPopup
          isVisible={isOtpVisible}
          onClose={() => setIsOtpVisible(false)}
          onSubmit={handleOtpSubmit}
        />
      </div>
    </>
  );
}

export default LoginPage;

