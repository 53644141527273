import './App.css';
import NavBar from './Components/NavBar';
import './index.css';
import LandingPage from './Pages/LandingPage';
import LoginPage from './Pages/LoginPage';
import DashBoard from './Pages/DashBoard/Dashboard';
import Project from './Pages/Projects/Project';
import NotFoundPage from './Pages/NotFoundPage';
import AuthForm from './Pages/Auth/AuthForm';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import ProjectView from './Pages/Projects/ProjectView';
function App() {

  return (
    <>
      <BrowserRouter>
        {/* <NavBar></NavBar> */}
        <Routes>
          <Route exact path="/login" element={<LoginPage />}></Route>
          <Route exact path="/" element={<LandingPage />}></Route>
          <Route exact path="/dashboard" element={<DashBoard />}></Route>
          <Route exact path="/projects" element={<Project />}></Route>
          <Route exact path="/project-view" element={<ProjectView />}></Route>
          <Route exact path="/login-signup" element={<AuthForm />}></Route>
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
